import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i1 from '@spartacus/core';
import { LogoutEvent, GlobalMessageType, provideDefaultConfigFactory, facadeFactory, CxEvent } from '@spartacus/core';
import { Subscription } from 'rxjs';
import '@spartacus/storefront';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * User account event listener.
 */
class UserAccountEventListener {
  constructor(eventService, globalMessageService) {
    this.eventService = eventService;
    this.globalMessageService = globalMessageService;
    this.subscriptions = new Subscription();
    this.onAuth();
  }
  /**
   * Registers events for the auth events.
   */
  onAuth() {
    this.subscriptions.add(this.eventService.get(LogoutEvent).subscribe(() => {
      this.globalMessageService.add({
        key: 'authMessages.signedOutSuccessfully'
      }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
    }));
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  static {
    this.ɵfac = function UserAccountEventListener_Factory(t) {
      return new (t || UserAccountEventListener)(i0.ɵɵinject(i1.EventService), i0.ɵɵinject(i1.GlobalMessageService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserAccountEventListener,
      factory: UserAccountEventListener.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserAccountEventListener, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.EventService
  }, {
    type: i1.GlobalMessageService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class UserAccountEventModule {
  constructor(_userAccountEventListener) {
    // Intentional empty constructor
  }
  static {
    this.ɵfac = function UserAccountEventModule_Factory(t) {
      return new (t || UserAccountEventModule)(i0.ɵɵinject(UserAccountEventListener));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UserAccountEventModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserAccountEventModule, [{
    type: NgModule,
    args: [{}]
  }], () => [{
    type: UserAccountEventListener
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const USER_ACCOUNT_FEATURE = 'userAccount';
const USER_ACCOUNT_CORE_FEATURE = 'userAccountCore';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultUserAccountComponentsConfig() {
  const config = {
    featureModules: {
      [USER_ACCOUNT_FEATURE]: {
        cmsComponents: ['LoginComponent', 'ReturningCustomerLoginComponent', 'VerifyOTPTokenComponent', 'ReturningCustomerRegisterComponent', 'MyAccountViewUserComponent', 'ReturningCustomerOTPLoginComponent']
      },
      // by default core is bundled together with components
      [USER_ACCOUNT_CORE_FEATURE]: USER_ACCOUNT_FEATURE
    }
  };
  return config;
}
class UserAccountRootModule {
  static {
    this.ɵfac = function UserAccountRootModule_Factory(t) {
      return new (t || UserAccountRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UserAccountRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultUserAccountComponentsConfig)],
      imports: [UserAccountEventModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserAccountRootModule, [{
    type: NgModule,
    args: [{
      imports: [UserAccountEventModule],
      providers: [provideDefaultConfigFactory(defaultUserAccountComponentsConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class UserAccountFacade {
  static {
    this.ɵfac = function UserAccountFacade_Factory(t) {
      return new (t || UserAccountFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserAccountFacade,
      factory: () => (() => facadeFactory({
        facade: UserAccountFacade,
        feature: USER_ACCOUNT_CORE_FEATURE,
        methods: ['get', 'getById']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserAccountFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: UserAccountFacade,
        feature: USER_ACCOUNT_CORE_FEATURE,
        methods: ['get', 'getById']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class VerificationTokenFacade {
  static {
    this.ɵfac = function VerificationTokenFacade_Factory(t) {
      return new (t || VerificationTokenFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: VerificationTokenFacade,
      factory: () => (() => facadeFactory({
        facade: VerificationTokenFacade,
        feature: USER_ACCOUNT_CORE_FEATURE,
        methods: ['createVerificationToken']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VerificationTokenFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: VerificationTokenFacade,
        feature: USER_ACCOUNT_CORE_FEATURE,
        methods: ['createVerificationToken']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
var VERIFICATION_TOKEN_DIALOG_ACTION;
(function (VERIFICATION_TOKEN_DIALOG_ACTION) {
  VERIFICATION_TOKEN_DIALOG_ACTION["OK"] = "OK";
})(VERIFICATION_TOKEN_DIALOG_ACTION || (VERIFICATION_TOKEN_DIALOG_ACTION = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class UserAccountEvent extends CxEvent {}
class UserAccountChangedEvent extends UserAccountEvent {
  static {
    this.type = 'UserAccountChangedEvent';
  }
}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/** AUGMENTABLE_TYPES_END */

/**
 * Generated bundle index. Do not edit.
 */

export { USER_ACCOUNT_CORE_FEATURE, USER_ACCOUNT_FEATURE, UserAccountChangedEvent, UserAccountEvent, UserAccountEventListener, UserAccountEventModule, UserAccountFacade, UserAccountRootModule, VERIFICATION_TOKEN_DIALOG_ACTION, VerificationTokenFacade, defaultUserAccountComponentsConfig };
